export interface RankedStats {
  currentEvents: CurrentEvent[];
  event: Event;
}

export interface CurrentEvent {
  id: number;
  name: string;
  mode: string;
}

export interface Event {
  lastUpdate: Timestamp;
  id: number | null;
  name: string | null;
  mode: string | null;
  brawlersStats: BrawlerStats[];
}

export interface BrawlerStats {
  brawlerId: number;
  brawlerName: string;
  wins: number;
  loses: number;
  totalBattles: number;
  winRate: number | null;
  useRate: number | null;
  effectivityRate: number | null;
}

export interface CurrentEvents {
  lastUpdate: Date;
  currentEvents: CurrentEvent[];
}

export interface Timestamp {
  seconds: number;
  nanoseconds: number;
}

export const ALL_ID = "all";

export type RankedStatsId = typeof ALL_ID | `${number}`;




export interface HttpFirestoreDocument<T> {
  name: string;
  fields: T;
  createTime: string;
  updateTime: string;
}

export type FirestoreFieldValue =
  | { timestampValue: string }
  | { stringValue: string }
  | { integerValue: string }
  | { doubleValue: number }
  | { nullValue: null };

export interface HttpFirestoreCurrentEvent {
  lastUpdate: FirestoreFieldValue;
  currentEvents: {
    arrayValue: {
      values: {
        mapValue: {
          fields: {
            mode: FirestoreFieldValue;
            name: FirestoreFieldValue;
            id: FirestoreFieldValue;
          };
        };
      }[];
    };
  };
}

export interface HttpFirestoreEvent {
  id: FirestoreFieldValue;
  brawlersStats: {
    arrayValue: {
      values: {
        mapValue: {
          fields: {
            wins: FirestoreFieldValue;
            totalBattles: FirestoreFieldValue;
            loses: FirestoreFieldValue;
            brawlerId: FirestoreFieldValue;
            winRate: FirestoreFieldValue;
            useRate: FirestoreFieldValue;
            brawlerName: FirestoreFieldValue;
            effectivityRate: FirestoreFieldValue;
          };
        };
      }[];
    };
  };
  name: FirestoreFieldValue;
  mode: FirestoreFieldValue;
  lastUpdate: FirestoreFieldValue;
}