import { DEFAULT_LANGUAGE, Languages } from "../../hooks/language/language.types";
import { AlternateLang } from "./seo-helmet.types";

export const getAlternatesLang = (baseUrl: string, canonical: string): AlternateLang[] => {
    const alternatesLang: AlternateLang[] = []
    alternatesLang.push({ lang: DEFAULT_LANGUAGE, url: canonical })
    const languages = Object.values(Languages).filter(lang => lang !== DEFAULT_LANGUAGE)
    const fullPath = canonical.replace(baseUrl, "")
    const langAlternatesOptions = languages.map(lang => {
        const fullUrl = `${baseUrl}/${lang}${fullPath}`
        const cleanUrl = fullUrl[fullUrl.length - 1] === "/" ? fullUrl.slice(0, -1) : fullUrl;
        return ({ lang: lang, url: cleanUrl })}
    );
    alternatesLang.push(...langAlternatesOptions)
    return alternatesLang
};
