import { Helmet } from "react-helmet";
import { DEFAULT_LANGUAGE, Languages } from "../../hooks/language/language.types";
import { useLanguage } from "../../hooks/language/language";
import { useLocation } from "react-router-dom";
import { getAlternatesLang } from "./seo-helmet.functions";
import { BASE_URL } from "./seo-helmet.constants";

interface Props {
  title: string;
  description: string;
  keywords: string;
}

export const SeoHelmet: React.FC<Props> = ({
  title,
  description,
  keywords
}: Props) => {
  const { getLanguage } = useLanguage();
  const location = useLocation();
  const currentUrl = `${BASE_URL}${location.pathname}${location.search}`;
  const language = getLanguage()

  const getDefaultURL = (language: Languages): string => {
    if (language === DEFAULT_LANGUAGE) {
      return BASE_URL + location.pathname + location.search
    } else {
      const path = location.pathname.split('/').filter(Boolean).slice(1).join('/')
      return BASE_URL + '/' + path + location.search
    }
  };
  const defaultUrl = getDefaultURL(language)

  const alternatesLang = getAlternatesLang(BASE_URL, defaultUrl)

  return (
    <Helmet htmlAttributes={{
      lang: language,
    }}>
      <link rel="canonical" href={currentUrl} />
      <link rel="alternate" hrefLang="x-default" href={defaultUrl} />
      {alternatesLang.map(alternate =>
        <link rel="alternate" hrefLang={alternate.lang} href={alternate.url} />
      )}

      <title>{title}</title>
      <meta name="description" content={description} />
      <meta name="keywords" content={keywords} />
      <meta property="og:url" content={currentUrl} />
      <meta property="og:title" content={title} />
      <meta property="og:description" content={description} />
      <meta property="og:locale" content={language} />
      <meta property="og:keywords" content={keywords} />

      <meta property="twitter:title" content={title} />
      <meta property="twitter:description" content={description} />
      <meta property="twitter:site" content="@topbrawl_" />
      <meta property="twitter:creator" content="@topbrawl_" />

      <script type="application/ld+json">
        {JSON.stringify({
          "@context": "https://schema.org",
          "@type": "WebSite",
          "name": "Topbrawl",
          "url": currentUrl,
          "description": description,
          "about": {
            "@type": "VideoGame",
            "name": "Brawl Stars"
          }
        })}
      </script>
    </Helmet>
  );
}
