import { NavBar } from "./routes/nav-bar/nav-bar";
import { BrowserRouter } from "react-router-dom";
import { AppRoutes } from "./routes/routes";
import theme from './theme';
import { ThemeProvider } from '@mui/material/styles';
import "./i18n";
import { analytics } from './configuration/firebase';
import { GlobalStylesCustom } from "./global-styles";
import { useEffect } from "react";
import { logEvent } from "firebase/analytics";

function App() {

  useEffect(() => {
    logEvent(analytics, 'load_app');
  }, []);
  
  return (
    <ThemeProvider theme={theme}>
      <GlobalStylesCustom />
      <BrowserRouter>
        <NavBar>
          <AppRoutes />
        </NavBar>
      </BrowserRouter>
    </ThemeProvider>

  );
}

export default App;
