import { useEffect, useCallback } from "react";
import { DEFAULT_LANGUAGE, Languages } from "./language.types";
import { useTranslation } from "react-i18next";

const LANGUAGE_KEY_STORAGE = 'language';

export const useLanguage = () => {
  const { i18n } = useTranslation();

  const saveLanguageStorage = (language: Languages) => {
    localStorage.setItem(LANGUAGE_KEY_STORAGE, language);
  };

  const formatLanguage = (language: string): string => language.split('-')[0];

  const isSupportedLang = useCallback((language: string): boolean =>
    Object.values(Languages).includes(formatLanguage(language) as Languages),
    []
  );

  const getLanguage = useCallback((): Languages => {
    const storedLanguage = localStorage.getItem(LANGUAGE_KEY_STORAGE) as Languages;

    if (storedLanguage) { return storedLanguage; }

    const detectedLanguage = formatLanguage(i18n.language);
    if (isSupportedLang(detectedLanguage)) { return detectedLanguage as Languages; }

    return DEFAULT_LANGUAGE;
  }, [i18n.language, isSupportedLang]);

  const setLanguage = useCallback((language: Languages) => {
    if (language !== i18n.language) {
      i18n.changeLanguage(language);
      saveLanguageStorage(language);
    }
  }, [i18n]);

  useEffect(() => {
    const initialLanguage = getLanguage();
    setLanguage(initialLanguage);
  }, [getLanguage, setLanguage]);

  return {
    getLanguage,
    isSupportedLang,
    setLanguage,
  };
};