import Box from "@mui/material/Box";
import { ALL_ID, CurrentEvent, RankedStatsId } from "../../../api/ranked-stats/ranked-stats.types";
import { Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { AllMapsContainer, MapContainerBtn, ModeContainer, ModeTitle, AllMapsTextContainer, TitleContainer, ImageMapContainer, MapContainer, ModeTitleContainer, ModeImage } from "./rankeds-maps.styles";
import { RankedMaps } from "./rankeds-maps.types";
import { transformToRankedMaps } from "./rankeds-maps.functions";
import { useTranslation } from "react-i18next";
import { battleModeColors } from "../../../theme";
import { useLanguage } from "../../../hooks/language/language";
import { APP_ROUTES } from "../../../routes/routes.constants";
import { getUrlWithLang } from "../../../routes/routes.functions";
import { LinkStyled } from "../../../components/basic-components-styled/basic-styled-componets";

interface Props {
  selectedMapId: RankedStatsId;
  currentEvents: CurrentEvent[];
  onClickMap: (eventId: RankedStatsId) => void;
}

export const RankedsMaps: React.FC<Props> = ({
  selectedMapId,
  currentEvents,
  onClickMap
}: Props) => {
  const { t } = useTranslation();
  const { getLanguage } = useLanguage();
  const language = getLanguage()
  const [baseMapsUrlLang, setBaseMapsUrlLang] = useState<string>(getUrlWithLang(language, APP_ROUTES.RANKEDS));
  const [rankedMaps, setRankedMaps] = useState<RankedMaps[]>([]);

  useEffect(() => {
    const eventsTransformed = transformToRankedMaps(currentEvents)
    setRankedMaps(eventsTransformed)
  }, [currentEvents]);

  useEffect(() => {
    setBaseMapsUrlLang(getUrlWithLang(language, APP_ROUTES.RANKEDS));
  }, [language]);


  return (
    <Box>
      <AllMapsContainer>
        <LinkStyled
          to={baseMapsUrlLang}
          onClick={() => onClickMap(ALL_ID)}
          aria-label={t("RANKEDS_PAGE.MAPS.ALL_MAPS")}>
          <MapContainerBtn isSelected={ALL_ID === selectedMapId}>
            <AllMapsTextContainer>
              <Typography component={ALL_ID === selectedMapId ? "h3" : "h2"}>
                {t("RANKEDS_PAGE.MAPS.ALL_MAPS")}
              </Typography>
            </AllMapsTextContainer>
          </MapContainerBtn>
        </LinkStyled>
      </AllMapsContainer>
      {rankedMaps.map((mode) => (
        <Box key={mode.mode}>
          <ModeTitleContainer>
            <ModeImage
              component="img"
              alt={`${mode.mode} Logo`}
              modeColor={battleModeColors[mode.mode as keyof typeof battleModeColors]}
              src={`/images/modes/${mode.mode}.webp`}
            />
            <ModeTitle component={ALL_ID === selectedMapId ? "h3" : "h2"} modeColor={battleModeColors[mode.mode as keyof typeof battleModeColors]}>
              {t(`DOMINE.MODE.${mode.mode}`).toUpperCase()}
            </ModeTitle>
          </ModeTitleContainer>
          <ModeContainer>
            {mode.maps.map((event) => (
              <LinkStyled
                to={`${baseMapsUrlLang}?${event.id}`}
                key={event.id}
                onClick={() => onClickMap(event.id)}
                aria-label={t("RANKEDS_PAGE.MAPS.MAP") + event.name}>
                <MapContainer
                  isSelected={event.id.toString() === selectedMapId}>
                  <TitleContainer>
                    <Typography>
                      {event.name}
                    </Typography>
                  </TitleContainer>
                  <ImageMapContainer
                    component="img"
                    alt={t("RANKEDS_PAGE.MAPS.MAP") + event.name}
                    src={`/images/maps/${event.id}.webp`}
                    onError={(e) => {
                      const target = e.target as HTMLImageElement;
                      target.src = '/images/maps/unknown.webp';
                    }}
                  />
                </MapContainer>
              </LinkStyled>
            ))}
          </ModeContainer>
        </Box>
      ))}
    </Box>
  );
}
