import * as React from 'react';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { DEFAULT_LANGUAGE, Languages } from '../../../hooks/language/language.types';
import { useLanguage } from '../../../hooks/language/language';
import { useLocation } from "react-router-dom";
import { LinkStyled } from '../../../components/basic-components-styled/basic-styled-componets';
import { useCallback } from 'react';


interface Props {
    anchor?: HTMLElement;
    onClose: (language?: Languages) => void;
}

export const LanguageMenu: React.FC<Props> = ({
    anchor,
    onClose
}) => {
    const { setLanguage, isSupportedLang } = useLanguage();
    const location = useLocation();
    const open = Boolean(anchor);

    const selectLanguage = useCallback((language: Languages): string => {
        const pathSegments = location.pathname.split('/').filter(Boolean);
        if (pathSegments[0] && isSupportedLang(pathSegments[0])) {
            if (language === DEFAULT_LANGUAGE) {
                pathSegments.shift();
            } else {
                pathSegments[0] = language;
            }
        } else {
            if (language !== DEFAULT_LANGUAGE) {
                pathSegments.unshift(language);
            }
        }
        return `/${pathSegments.join('/')}${location.search}`;
    }, [location, isSupportedLang]);

    const handleSelectLang = (language: Languages) => {
        setLanguage(language);
        onClose();
    }

    return (
        <Menu
            id="language-menu"
            anchorEl={anchor}
            open={open}
            onClose={() => onClose()}
        >
            <MenuItem
                onClick={() => handleSelectLang(Languages.English)}>
                <LinkStyled
                    to={selectLanguage(Languages.English)}
                    aria-label="English">English
                </LinkStyled>
            </MenuItem>
            <MenuItem
                onClick={() => handleSelectLang(Languages.Spanish)}>
                <LinkStyled
                    to={selectLanguage(Languages.Spanish)}
                    aria-label="Español">Español
                </LinkStyled>
            </MenuItem>
        </Menu>
    );
}