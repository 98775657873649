import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
//import { getFirestore } from "firebase/firestore";

export const projectId = "topbrawl-d96ec"
export const apiKey = "AIzaSyAHrKlPV1fy5MMksth6i89FcsNf8ZN-yao"

const firebaseConfig = {
  apiKey: apiKey,
  authDomain: "topbrawl-d96ec.firebaseapp.com",
  projectId: projectId,
  storageBucket: "topbrawl-d96ec.appspot.com",
  messagingSenderId: "323294959784",
  appId: "1:323294959784:web:dce9c7223d62db35e40a9d",
  measurementId: "G-YJ3HYDHZSV"
};

const app = initializeApp(firebaseConfig);
export const analytics = getAnalytics(app);
//export const firestoreDB = getFirestore(app);
