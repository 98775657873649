import { DEFAULT_LANGUAGE, Languages } from "../../hooks/language/language.types";
import { AlternateLang } from "./seo-helmet.types";

export const getAlternatesLang = (baseUrl: string, canonical: string): AlternateLang[] => {
    const alternatesLang: AlternateLang[] = []
    alternatesLang.push({ lang: DEFAULT_LANGUAGE, url: canonical })
    const languages = Object.values(Languages).filter(lang => lang !== DEFAULT_LANGUAGE)
    const fullPath = canonical.replace(baseUrl, "")
    const langAlternatesOptions = languages.map(lang => ({ lang: lang, url: `${baseUrl}/${lang}${fullPath}` }));
    alternatesLang.push(...langAlternatesOptions)
    return alternatesLang
};
