//import { doc, getDoc } from "firebase/firestore";
//import { firestoreDB } from "../configuration/firebase";
import { RankedStats, Event, RankedStatsId, HttpFirestoreDocument, HttpFirestoreCurrentEvent, CurrentEvent, HttpFirestoreEvent, Timestamp, BrawlerStats, FirestoreFieldValue } from "./ranked-stats.types";

const COLLECTION_RANKED = "ranked"
const DOCUMENT_CURRENT_EVENTS = "current-events"
const DOCUMENT_EVENT_PREFIX = "event-"

export const getRankedStats = async (id: RankedStatsId): Promise<RankedStats> => {
  const rankedStats = await getRankedStatsFromFirestoreHttp(id)
  return rankedStats
};


/* eslint-disable-next-line @typescript-eslint/no-unused-vars */
const getRankedStatsFromTopbrawlAPI = async (id: RankedStatsId): Promise<RankedStats> => {
  const response = await fetch(`http://localhost:8000/stats/ranked/${id}`, {});
  if (response.ok) {
    return response.json();
  } else {
    const responseError = await response.json();
    const error = new Error(responseError);
    throw error;
  }
};


//This way not works with googlebot, google bot not admits sockets, or almost is difficult to him get data.
/* const getRankedStatsFromFirestoreSockets = async (id: RankedStatsId): Promise<RankedStats> => {
  const docRefCurrentEvents = doc(firestoreDB, COLLECTION_RANKED, DOCUMENT_CURRENT_EVENTS);
  const querySnapshotCurrentEvents = await getDoc(docRefCurrentEvents);
  const currentEvents = querySnapshotCurrentEvents.data() as CurrentEvents;
  const docRefEvent = doc(firestoreDB, COLLECTION_RANKED, DOCUMENT_EVENT_PREFIX + id);
  const querySnapshotEvent = await getDoc(docRefEvent);
  const event = querySnapshotEvent.data() as Event;
  const rankedStats: RankedStats = { currentEvents: currentEvents.currentEvents, event: event }
  return rankedStats
}; */

const getRankedStatsFromFirestoreHttp = async (id: RankedStatsId): Promise<RankedStats> => {
  try {
    const currentEventsResponse = await fetch(`https://firestore.googleapis.com/v1/projects/topbrawl-d96ec/databases/(default)/documents/${COLLECTION_RANKED}/${DOCUMENT_CURRENT_EVENTS}?key=AIzaSyAHrKlPV1fy5MMksth6i89FcsNf8ZN-yao`);
    const currentEventsJson = await currentEventsResponse.json();
    const currentEventsTyped = currentEventsJson as HttpFirestoreDocument<HttpFirestoreCurrentEvent>;
    const currentEvents = convertHttpFirestoreCurrentEventsToCurrentEvents(currentEventsTyped)
    const eventResponse = await fetch(`https://firestore.googleapis.com/v1/projects/topbrawl-d96ec/databases/(default)/documents/${COLLECTION_RANKED}/${DOCUMENT_EVENT_PREFIX}${id}?key=AIzaSyAHrKlPV1fy5MMksth6i89FcsNf8ZN-yao`);
    const eventJson = await eventResponse.json();
    const eventTyped = eventJson as HttpFirestoreDocument<HttpFirestoreEvent>;

    const event = convertHttpFirestoreEventToEvent(eventTyped)
    const rankedStats: RankedStats = {
      event: event,
      currentEvents: currentEvents
    }
    return rankedStats
  } catch (error) {
    throw new Error(`${error}: ${id}`);
  }
};

const convertHttpFirestoreCurrentEventsToCurrentEvents = (firestoreCurrentEventsHttp: HttpFirestoreDocument<HttpFirestoreCurrentEvent>): CurrentEvent[] => {
  const { fields } = firestoreCurrentEventsHttp;

  const currentEvents: CurrentEvent[] = fields.currentEvents.arrayValue.values.map(value => {
    const event = value.mapValue.fields;
    return {
      id: getIntegerValue(event.id)!,
      name: getStringValue(event.name)!,
      mode: getStringValue(event.mode)!,
    };
  });
  return currentEvents;
};

const convertHttpFirestoreEventToEvent = (firestoreRankedEvent: HttpFirestoreDocument<HttpFirestoreEvent>): Event => {
  const fields = firestoreRankedEvent.fields;

  const lastUpdate: Timestamp = {
    seconds: new Date(getTimestampValue(fields.lastUpdate)!).getTime() / 1000,
    nanoseconds: 0,
  };

  const brawlersStats: BrawlerStats[] = fields.brawlersStats.arrayValue.values.map(value => {
    const stats = value.mapValue.fields;
    return {
      brawlerId: getIntegerValue(stats.brawlerId)!,
      brawlerName: getStringValue(stats.brawlerName)!,
      wins: getIntegerValue(stats.wins)!,
      loses: getIntegerValue(stats.loses)!,
      totalBattles: getIntegerValue(stats.totalBattles)!,
      winRate: getDoubleValue(stats.winRate),
      useRate: getDoubleValue(stats.useRate),
      effectivityRate: getDoubleValue(stats.effectivityRate),
    };
  });

  const event: Event = {
    lastUpdate: lastUpdate,
    id: getIntegerValue(fields.id),
    name: getStringValue(fields.name),
    mode: getStringValue(fields.mode),
    brawlersStats: brawlersStats,
  };

  return event;
};

const getTimestampValue = (field: FirestoreFieldValue): string | null =>
  "timestampValue" in field ? field.timestampValue : null;

const getIntegerValue = (field: FirestoreFieldValue): number | null =>
  "integerValue" in field ? parseInt(field.integerValue, 10) : null;

const getDoubleValue = (field: FirestoreFieldValue): number | null =>
  "doubleValue" in field ? field.doubleValue : null;

const getStringValue = (field: FirestoreFieldValue): string | null =>
  "stringValue" in field ? field.stringValue : null;