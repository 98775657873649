import { useTranslation } from "react-i18next";
import { useState } from "react";
import { DrawerContainer, DrawerContainerMb, DrawerPageContainer, DrawerPageContainerMb, LogoContainer, MainContainer, NavItemSelectorContainer, PageContainer, PageContainerMb, StyledIcon, TextItems, TopBar, TopBarElementsContainer, TopBarElementsContainerMb, WebTitle } from "./nav-bar.styles";
import { APP_ROUTES } from "../routes.constants";
import { useLocation } from "react-router-dom";
import Box from "@mui/material/Box";
import useMediaQuery from "@mui/material/useMediaQuery";
import { screenBreackpoints } from "../../theme";
import Icon from "@mui/material/Icon";
import IconButton from "@mui/material/IconButton";
import { useLanguage } from "../../hooks/language/language";
import { LanguageMenu } from "./language-menu/language-menu";
import { LinkStyled } from "../../components/basic-components-styled/basic-styled-componets";
import { getUrlWithLang } from "../routes.functions";

interface Props {
  children?: React.ReactNode;
}

interface INavItem {
  id: number;
  label: string;
  icon: string;
  route: string;
}

export const NavBar: React.FC<Props> = ({ children }) => {
  const isMobile = useMediaQuery(`(max-width:${screenBreackpoints.mobile})`);
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const location = useLocation();
  const { isSupportedLang, getLanguage } = useLanguage();
  const language = getLanguage()
  const [languageMenuAnchor, setLanguageMenuAnchor] = useState<undefined | HTMLElement>(undefined);

  const handleNavigation = () => {
    if (isMobile) { setIsOpen(false) }
  };

  const isSelectedItem = (route: string): boolean => {
    const pathSegments = location.pathname.split('/').filter(Boolean);
    const lastSegment = pathSegments[pathSegments.length - 1];
    const isHome = lastSegment === undefined || isSupportedLang(lastSegment)
    return lastSegment === route ||
      (isHome && route === APP_ROUTES.RANKEDS) //Delete this when a home url is added. Jordi. 28/10/2024
  };

  const toggleDrawer = (open: boolean) => {
    setIsOpen(open)
  };

  const openLanguageMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
    setLanguageMenuAnchor(event.currentTarget);
  };

  const items: INavItem[] = [
    {
      id: 1,
      label: t("NAV_BAR.RANKED"),
      icon: "swords",
      route: APP_ROUTES.RANKEDS
    },
    {
      id: 2,
      label: t("NAV_BAR.ABOUT_US"),
      icon: "contact_support",
      route: APP_ROUTES.ABOUT_US
    },
  ]

  const renderLogo = () => (
    <LogoContainer>
      <Box
        component="img"
        alt="Topbrawl Logo"
        sx={{ height: 40, width: 40 }}
        src={`/logo192.png`}
      />
      <WebTitle>Topbrawl</WebTitle>
    </LogoContainer>
  );

  const renderDrawerItems = (items: INavItem[]) => (
    items.map((item) => (
      <LinkStyled
        to={getUrlWithLang(language, item.route)}
        key={item.id}
        onClick={() => handleNavigation()}
        aria-label={item.label}>
        <NavItemSelectorContainer
          isSelected={isSelectedItem(item.route)}>
          <StyledIcon baseClassName="material-symbols-outlined" isSelected={isSelectedItem(item.route)}>{item.icon}</StyledIcon>
          <TextItems isOpen={isOpen} isSelected={isSelectedItem(item.route)}>{item.label}</TextItems>
        </NavItemSelectorContainer>
      </LinkStyled>
    ))
  );

  return (
    <MainContainer>
      {isMobile ?
        <div>
          <TopBar>
            <TopBarElementsContainerMb>
              <IconButton
                onClick={() => setIsOpen(!isOpen)}
                aria-label="Menu">
                <Icon baseClassName="material-symbols-outlined">menu</Icon>
              </IconButton>
              <LinkStyled
                to={getUrlWithLang(language, APP_ROUTES.HOME)}
                onClick={() => handleNavigation()}
                aria-label={t("Topbrawl")}>
                {renderLogo()}
              </LinkStyled>
              <IconButton
                onClick={openLanguageMenu}
                aria-label={t("NAV_BAR.LANGUAGES")}>
                <Icon baseClassName="material-symbols-outlined">translate</Icon>
              </IconButton>
            </TopBarElementsContainerMb>
          </TopBar>
          <DrawerPageContainerMb>
            <DrawerContainerMb isOpen={isOpen}>
              {renderDrawerItems(items)}
            </DrawerContainerMb>
            <PageContainerMb>
              {children}
            </PageContainerMb>
          </DrawerPageContainerMb>
        </div>
        :
        <div>
          <TopBar>
            <TopBarElementsContainer>
              <LinkStyled
                to={getUrlWithLang(language, APP_ROUTES.HOME)}
                onClick={() => handleNavigation()}
                aria-label={t("Topbrawl")}>
                {renderLogo()}
              </LinkStyled>
              <IconButton
                onClick={openLanguageMenu}
                aria-label={t("NAV_BAR.LANGUAGES")}>
                <Icon baseClassName="material-symbols-outlined">translate</Icon>
              </IconButton>
            </TopBarElementsContainer>
          </TopBar>
          <DrawerPageContainer>
            <DrawerContainer isOpen={isOpen} onMouseEnter={() => toggleDrawer(true)} onMouseLeave={() => toggleDrawer(false)}>
              {renderDrawerItems(items)}
            </DrawerContainer>
            <PageContainer>
              {children}
            </PageContainer>
          </DrawerPageContainer>
        </div>
      }
      <LanguageMenu anchor={languageMenuAnchor} onClose={() => setLanguageMenuAnchor(undefined)} />
    </MainContainer>
  );
};
