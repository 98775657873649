import { createTheme } from '@mui/material/styles';

export const primaryPalette = {
  50: '#e3e6ea',
  100: '#b9bfca',
  200: '#8a95a7',
  300: '#5b6b83',
  400: '#374b69',
  500: '#142b4e',
  600: '#122647',
  700: '#0e203d',
  800: '#0b1a35',
  900: '#061025',
  A100: '#618bff',
  A200: '#2e66ff',
  A400: '#0043fa',
  A700: '#003ce1',
  contrast: {
    50: '#000000',
    100: '#000000',
    200: '#000000',
    300: '#ffffff',
    400: '#ffffff',
    500: '#ffffff',
    600: '#ffffff',
    700: '#ffffff',
    800: '#ffffff',
    900: '#ffffff',
    A100: '#000000',
    A200: '#ffffff',
    A400: '#ffffff',
    A700: '#ffffff',
  }
}

export const secondaryPalette = {
  50: '#fdf8e6',
  100: '#fbedc2',
  200: '#f8e199',
  300: '#f5d470',
  400: '#f3cb51',
  500: '#f1c232',
  600: '#efbc2d',
  700: '#edb426',
  800: '#ebac1f',
  900: '#e79f13',
  A100: '#ffffff',
  A200: '#fff4e2',
  A400: '#ffe2af',
  A700: '#ffd896',
  contrast: {
    50: '#000000',
    100: '#000000',
    200: '#000000',
    300: '#000000',
    400: '#000000',
    500: '#000000',
    600: '#000000',
    700: '#000000',
    800: '#000000',
    900: '#000000',
    A100: '#000000',
    A200: '#000000',
    A400: '#000000',
    A700: '#000000'
  }
}

export const paletteBlueBrawler = {
  50: '#e2ecf6',
  100: '#b7cfe7',
  200: '#87afd8',
  300: '#568fc8',
  400: '#3277bc',
  500: '#0e5fb0',
  600: '#0c57a9',
  700: '#0a4da0',
  800: '#084397',
  900: '#043287',
  A100: '#b3c9ff',
  A200: '#80a5ff',
  A400: '#4d81ff',
  A700: '#346fff',
  contrast: {
    50: '#000000',
    100: '#000000',
    200: '#000000',
    300: '#000000',
    400: '#ffffff',
    500: '#ffffff',
    600: '#ffffff',
    700: '#ffffff',
    800: '#ffffff',
    900: '#ffffff',
    A100: '#000000',
    A200: '#000000',
    A400: '#ffffff',
    A700: '#ffffff',
  }
}

export const paletteBlueGreenBrawler = {
  50: '#e4e7ed',
  100: '#bac3d1',
  200: '#8d9cb3',
  300: '#5f7494',
  400: '#3c567d',
  500: '#1a3866',
  600: '#17325e',
  700: '#132b53',
  800: '#0f2449',
  900: '#081738',
  A100: '#7096ff',
  A200: '#3d70ff',
  A400: '#0a4bff',
  A700: '#003ff0',
  contrast: {
    50: '#000000',
    100: '#000000',
    200: '#000000',
    300: '#ffffff',
    400: '#ffffff',
    500: '#ffffff',
    600: '#ffffff',
    700: '#ffffff',
    800: '#ffffff',
    900: '#ffffff',
    A100: '#000000',
    A200: '#ffffff',
    A400: '#ffffff',
    A700: '#ffffff',
  }
}

export const componentColors = {
  bar: primaryPalette[900],
  barContrast: primaryPalette.contrast[900],
  pageBackground: primaryPalette[800],
  pageContrast: primaryPalette.contrast[800],
  divider: primaryPalette[500],
  card: primaryPalette[400],
  cardText: primaryPalette.contrast[400],
  cardSelected: "#32649d",
  cardBorder: primaryPalette[300],
  selectedItem: secondaryPalette[600],
  linkText: secondaryPalette[600],
  secondaryHintText: "#00CCFF"
}

export const battleModeColors = {
  BRAWL_BALL: "#8ca0df",
  KNOCKOUT: "#f7831c",
  HOT_ZONE: "#e33c50",
  GEM_GRAB: "#9b3df3",
  HEIST: "#d65cd3",
  BOUNTY: "#01cfff",
  SHOWDOWN: "#81d621"
}

export const componentStyles = {
  cardRadius: '5px',
}

export const fontSize = {
  default: 14,
  mobile: 14
}

export const screenBreackpoints = {
  mobile: '1024px'
}

const theme = createTheme({
  palette: {
    mode: 'dark',
    primary: {
      main: primaryPalette[500],
    },
    secondary: {
      main: secondaryPalette[500],
    },
  },
  typography: {
    fontFamily: ["Lilita One", "sans-serif"].join(","),
    fontSize: fontSize.default,
  },
});


export default theme;